import { Controller } from "@hotwired/stimulus"
import FastSpringController from "./fastspring_controller";

export default class extends Controller {
    static targets = ['elements', 'suggestionsSelect', 'formTarget'];

    disable_check(e) {
        e.target.closest()
    }

    check_radio(event) {
        document.querySelector(`#${event.params.checkBoxId}`).checked = true;
    }

    click_link(event) {
        document.querySelector(`#${event.params.linkId}`).click();
    }

    fetchData(inputValue) {
        const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
        const token = "226d4a6e74304a9809013aabf341ff5d7a04285f";

        const options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({ query: inputValue })
        };

        return fetch(url, options)
            .then(response => response.json())
            .then(data => data.suggestions)
            .catch(error => {
                return [];
            });
    }

    async fetch_company(event) {
        const input = event.target;
        if (input.name === "inn") {
            const inputValue = input.value.trim();

            if (inputValue !== "") {
                const suggestions = await this.fetchData(inputValue);
                this.renderSuggestions(suggestions);
            }
        }
    }

    renderSuggestions(suggestions) {
        const select = this.suggestionsSelectTarget;
        select.innerHTML = "";

        suggestions.forEach((suggestion) => {
            const option = document.createElement("option");
            option.value = suggestion.data.inn;
            option.textContent = `${suggestion.value} (${suggestion.data.inn})`;
            select.appendChild(option);
        });

        select.dataset.suggestions = JSON.stringify(suggestions); // Store the suggestions data in the data-suggestions attribute
        select.classList.remove('hidden');
    }

    selectChanged() {
        const select = this.suggestionsSelectTarget;
        const selectedOption = select.options[select.selectedIndex];
        const selectedInn = selectedOption.value;

        const suggestions = JSON.parse(select.dataset.suggestions); // Retrieve the stored suggestions data

        const suggestion = suggestions.find((suggestion) => suggestion.data.inn === selectedInn);
        if (suggestion) {
            const form = select.closest("form");
            form.querySelector("#company").value = suggestion.data.name.full;
            form.querySelector("#inn").value = suggestion.data.inn;
            if ( suggestion.data.phone ) {
                form.querySelector("#phone_number").value = suggestion.data.phone;
            }
            form.querySelector("#address").value = suggestion.data.address.value;
            form.querySelector("#postal_code").value = suggestion.data.address.data.postal_code;
            form.querySelector("#kpp").value = suggestion.data.kpp;
            select.classList.add('hidden');
        }
    }

    getSuggestionByInn(inn) {
        const suggestions = this.suggestionsSelectTarget.dataset.suggestions;
        if (suggestions) {
            const parsedSuggestions = JSON.parse(suggestions);
            return parsedSuggestions.find((suggestion) => suggestion.data.inn === inn);
        }
        return null;
    }

    validateFields(event) {
        event.preventDefault();

        let formValid = true;
        const fields = this.element.querySelectorAll("input, checkbox, textarea");
        const maxLength = 80;

        fields.forEach((field) => {
            if (field.closest('.invisible') || field.name == "authenticity_token") {
                return;
            }

            if (field.classList.contains("required")) {

                if (field.value === undefined || field.value.trim() === "") {
                    field.classList.add("border", "border-red-500");
                    formValid = false;
                    return;
                } else if (field.type === 'checkbox' && !field.checked) {
                    field.parentNode.classList.add('border', 'border-primary', 'rounded-md');
                    formValid = false;
                    return;
                }
                else {
                    field.classList.remove( "border-red-500");
                }

            }

            const fieldMaxLength = field.dataset.maxLength > 0 ? field.maxLength : maxLength;

            if (field.value.length > fieldMaxLength) {
                field.classList.add("border-red-500");
                formValid = false;
                return;
            } else {
                field.classList.remove("border-red-500");
            }
        });

        const emailField = this.element.querySelector("input[type='email'], input[name='email']");
        if (emailField && !this.isValidEmail(emailField.value)) {
            emailField.classList.add("border-red-500");
            formValid = false;
        } else if (emailField) {
            emailField.classList.remove("border-red-500");
        }


        if (formValid) {
            const form = this.element.closest('form');

            const formData = Object.fromEntries(new FormData(form).entries());
            const csrfToken = document.querySelector("[name='csrf-token']").content;

            fetch(this.element.getAttribute("data-payment-check-path"), {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": csrfToken
                },
                body: JSON.stringify(formData),
              }).then(response => response.json())
                .then(data => {
                    if (data.submit == true) {
                        this.element.submit();
                    } else {
                        const controllerName = data.render;
                        const params = data.params;
                        const customer = {
                            firstName: formData.name,
                            lastName: formData.last_name,
                            email: formData.email,
                            company: formData.company || '',
                            address: {
                                country: formData.country,
                                city: formData.city,
                                street: formData.street,
                                zip: formData.zip
                            }
                        };

                        if (controllerName === "fast_spring_controller") {
                            const fastSpring = new FastSpringController();

                            fastSpring.initializeWithParams({
                                products: params.products.map(product => ({
                                    code: product.reg_code,
                                    quantity: product.quantity
                                })),
                                orderId: params.order_id,
                                customer: customer
                            });
                        } else {
                            console.error("Unsupported controller:", controllerName);
                        }
                    }
                })

        }
    }

    isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }
}
