import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { products: Array, order: String, customer: Object };

  initializeWithParams({ products, orderId, customer }) {
    this.productsValue = products;
    this.orderValue = orderId;
    this.customerValue = customer;

    this.loadFastSpringLibrary();
  }

  loadFastSpringLibrary() {
    const scriptId = "fsc-api";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js";
      script.type = "text/javascript";
      script.dataset.storefront = "fastreport.onfastspring.com/popup-defaultB2B";
      document.head.appendChild(script);

      script.onload = () => {
        this.addProductsAndCheckout();
      };

      script.onerror = () => {
        console.error("Failed to load FastSpring Library");
      };
    } else {
      this.addProductsAndCheckout();
    }
  }



  addProductsAndCheckout() {
    const productData = this.productsValue.map((product) => ({
      path: product.code,
      quantity: product.quantity
    }));
    fastspring.builder.tag({'order_id': this.orderValue});
    fastspring.builder.push(
      {
        products: productData,
        referrer: `order_id=${this.orderValue}`,
        language: "en",
        paymentContact: {
                          email: this.customerValue.email,
                          firstName: this.customerValue.firstName,
                          lastName: this.customerValue.lastName,
                          company: this.customerValue.company,
                          addressLine1: this.customerValue.address.street,
                          city: this.customerValue.address.city,
                          region: "",
                          country: this.customerValue.address.country,
                          postalCode: this.customerValue.address.zip,
                          phoneNumber: ""
                        }
      },
      (data) => {

        fastspring.builder.checkout({
          referrer: `order_id=${this.orderValue}`,
          tags: { order_id: this.orderValue }
        });
      }
    );
  }
}
